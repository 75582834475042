import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '@/views/auth/Login.vue';
import Signup from '@/views/auth/Signup.vue';
import Dashboard from '@/views/Dashboard.vue';
import Roadbooks from '@/views/Roadbooks.vue';
import RoadbookEditRouting from '@/views/RoadbookEditRouting.vue';
import RoadbookEditDiscovery from '@/views/RoadbookEditDiscovery.vue';
import Credits from '@/views/Credits.vue';
import Account from '@/views/Account.vue';
import Parameters from '@/views/Parameters.vue';
import Devices from '@/views/Devices.vue';
import NotFound from '@/views/NotFound.vue';
import Community from '@/views/Community.vue';
import RoadbookEditFlash from '@/views/RoadbookEditFlash.vue';
import RecoverPassword from '@/views/auth/RecoverPassword.vue';
import Confidentality from '@/views/Confidentality.vue';
import ConditionsOfUse from '@/views/ConditionsOfUse.vue';
import ChangePassword from '@/views/auth/ChangePassword.vue';
import VerifyAccount from '@/views/auth/VerifyAccount.vue';
import OAuthRedirect from '@/views/auth/OAuthRedirect.vue';
import UsernameCreation from '@/views/auth/UsernameCreation.vue';
import { AuthStore } from '@/stores/AuthStore';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { sidemenu: false }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: { sidemenu: false }
  },
  {
    path: '/resetpassword',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: { sidemenu: false }
  },
  {
    path: '/changepassword/:email/token/:token/', // Important : final '/' is mandatory to router for long token
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { sidemenu: false },
  },
  {
    path: '/verify/:email/token/:token/', // Important : final '/' is mandatory to router for long token
    name: 'VerifyAccount',
    component: VerifyAccount,
    meta: { sidemenu: false },
  },
  {
    path: '/oauth2redirect',
    name: 'OAuth2Redirect',
    component: OAuthRedirect,
    meta: { sidemenu: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { sidemenu: 'navigation' }
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    meta: { sidemenu: 'navigation' }
  },
  {
    path: '/roadbooks',
    name: 'Roadbooks',
    component: Roadbooks,
    meta: { sidemenu: 'navigation' }
  },
  {
    path: '/roadbooks/:id/edit/routing',
    name: 'RoadbookEditRouting',
    component: RoadbookEditRouting,
    meta: { sidemenu: false }
  },
  {
    path: '/roadbooks/:id/edit/discovery',
    name: 'RoadbookEditDiscovery',
    component: RoadbookEditDiscovery,
    meta: { sidemenu: false }
  },
  {
    path: '/roadbooks/:id/edit/flash',
    name: 'RoadbookEditFlash',
    component: RoadbookEditFlash,
    meta: { sidemenu: false }
  },
  {
    path: '/credits',
    name: 'Credits',
    component: Credits,
    meta: { sidemenu: 'navigation' }
  },
  {
    path: '/conditions-of-use',
    name: 'ConditionsOfUse',
    component: ConditionsOfUse,
    meta: { sidemenu: false }
  },
  {
    path: '/confidentality',
    name: 'Confidentality',
    component: Confidentality,
    meta: { sidemenu: false }
  },
  {
    path: '/user/defineusername',
    name: 'UsernameCreation',
    component: UsernameCreation,
    meta: { sidemenu: false }
  },
  {
    path: '/user/account',
    name: 'Account',
    component: Account,
    meta: { sidemenu: 'navigation' }
  },
  {
    path: '/parameters',
    name: 'Parameters',
    component: Parameters,
    meta: { sidemenu: 'navigation' }
  },
  {
    path: '/devices',
    name: 'Devices',
    component: Devices,
    meta: { sidemenu: 'navigation' }
  },
  {
    path: '/roadbook/oops',
    name: 'NotFound',
    component: NotFound,
    meta: { sidemenu: false }
  },
  // redirect any other location to dashboard
  { 
    path: '/:pathMatch(.*)*', 
    redirect: '/dashboard' 
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})


/*
  Router Guard : redirect user to define an accound 'username' 
                 This use case is procuced after OAuth2 (Google/Facebook/AppleID) account creation
*/
router.beforeEach((to, from)=>{
//  const authStore = AuthStore();
//  let isAuthentified = authStore.token()
//  let user:any = authStore.userData()
//  if(isAuthentified && ! user.username && to.path != '/user/defineusername'){
//    return '/user/defineusername'
//  }
});


export default router
