import { presentToast } from '@/libs/userExperience';
import { AuthStore } from '@/stores/AuthStore'
import router from '@/router'
import { i18n } from '@/main';


export class BackendAPI {

    public backendUrl:string = import.meta.env.VITE_TRIPYMAP3_BACKEND;
    public apiVersion:string = import.meta.env.VITE_TRIPYMAP3_BACKEND_API_VERSION;
    public requestHeader:any = {'Content-Type': 'application/json'}
    public relativeUrl:string = '';
    public disableErrorToasts:boolean = false;
    public nextErrorToastDisabled:boolean = false;

    constructor(relativeUrl:string){
        this.relativeUrl = relativeUrl;
    }

    protected url(){
        return `${this.backendUrl}/${this.apiVersion}${this.relativeUrl}`;
    }

    protected async error(error:string=''){
        if(this.disableErrorToasts){
            return
        }
        if(this.nextErrorToastDisabled){
            this.nextErrorToastDisabled = false;
        }
        else{
            if(import.meta.env.DEV){ 
                // Display real error for developer
                console.error(error) 
                presentToast(i18n.global.t(error), 'danger');
            }
            else{
                // Display generic error message for production
                presentToast(i18n.global.t('An error occured'), 'danger');
            }
        }
    }

    protected async inspectResult(response:any){
        let data:any = {}
        if(response.headers.get('content-type') && response.headers.get('content-type').includes('application/json'))
            data = await response.json()
        else if(response.body && response.body instanceof ReadableStream){
            data = response.blob();
        }
        else if(response.body){
            data = response.body
        }
        if(response.status == 401){
            router.replace('/login')
        }
        else if(response.status >= 400) {
            this.error(data.detail)
        }
        return data;
    }

    protected fetch(url:string, options:any={}){
        if(options.headers == undefined) options.headers = {}

        if(options.headers && options.headers['Content-Type'] === undefined 
            && options.body instanceof FormData) { 
            // Nothing to do. FormData will be auto detected by 'fetch' and add 'Content-Type: multipart/form-data';    
        }
        else if(options.headers && options.headers['Content-Type'] === undefined 
            && options.body instanceof Object) { 
            options.headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify(options.body)
        }

        let authToken = AuthStore().token();
        if(authToken && ! options.authDisabled){
            options.headers['Authorization'] = `Bearer ${authToken}`
        }

        if(options.params){
            url = url + '?' + new URLSearchParams(options.params).toString();
        }
        
        return fetch(url, options) // here use global Typescript fetch() function
        .then(async r => {return this.inspectResult(r)})
        // .catch((error)=> { console.log('FETCH CATCH'); this.error(error)})
    }

    public disableNextErrorToast(){
        this.nextErrorToastDisabled = true;
    }

    public all(params:any={}){
        return this.fetch(this.url(),{
            method: 'GET',
            params: params
        });
    }

    public get(id_or_path:string, params:any={}){
        if(! id_or_path.startsWith('/')){
            id_or_path = '/' + id_or_path;  
        } 
        return this.fetch(this.url()+id_or_path, {
            params: params
        });
    }

    public post(url='', data:any={}){
        return this.fetch(this.url()+url,{
            method: 'POST',
            body: data
        });
    }
    
    public create(data:any){
        return this.post('', data);
    }
    
    public delete(id:string){
        return this.fetch(this.url()+'/'+id,{
            method: 'DELETE',
        });
    }
    
    public update(id:string, data:any){
        return this.fetch(this.url()+'/'+id,{
            method: 'PUT',
            body: data
        });
    }

    public patch(id:string, data:any){
        return this.fetch(this.url()+'/'+id,{
            method: 'PATCH',
            body: data
        });
    }

    public download(downloadUrl:string, params:any={}){
        if(!('headers' in params)) {
            params.headers = this.requestHeader;
        }
        if('query' in params){
            downloadUrl += '?' + ( new URLSearchParams( params.query ) ).toString();
        }

        return fetch(this.url()+downloadUrl, params)
        .then( res => {
            if(res.status >= 400) this.error()
            return res.blob() 
        })
        .catch((error)=> {this.error(error)})
    }

    public upload(uploadUrl:string, data:FormData){
        return this.fetch(this.url()+uploadUrl,{
            method: 'POST',
            headers: {},
            body: data
        });
    }
}

export class AuthAPI extends BackendAPI{
    constructor() { 
        super('/auth'); 
        this.disableErrorToasts = true;
    }

    public login(login:string, password:string){
        var formData = new FormData()
        formData.append('username', login)
        formData.append('password', password)
        return this.post('/login', formData);
    }

    public logout(){
        return this.post('/logout');
    }

    public signup(signupData:any){
        return this.post('/register', signupData);
    }

    public forgotPassword(email:string){
        return this.post('/forgot-password', {email: email});
    }

    public resetPassword(token:string, password:string){
        return this.post('/reset-password', {token: token, password: password});
    }

    public requestAccountVerification(email:string){
        return this.post('/request-verify-token', {email:email});
    }

    public verifyAccount(token:string){
        return this.post('/verify', {token:token});
    }

    public FacebookOAuth2(){
        return this.get('/facebook/authorize');
    }

    public FacebookOAuth2Login(params:any){
        return this.get('/facebook/callback', params);
    }

    public GoogleOAuth2(){
        return this.get('/google/authorize');
    }

    public GoogleOAuth2Login(params:any){
        return this.get('/google/callback', params);
    }

    public AppleOAuth2(){
        return this.get('/apple/authorize');
    }

    public AppleOAuth2Login(params:any){
        return this.get('/apple/callback', params);
    }
}
export const authAPI:AuthAPI = new AuthAPI();


export class UserAPI extends BackendAPI{
    constructor() { super('/user'); }

    public verifyUsername(username:string){
        return this.post('/validate/username', {username:username});
    }

    public userInfos(){
        return this.get('/me');
    }

    public updateInfos(data:any){
        return this.patch('me', data);
    }
}
export const userAPI:UserAPI = new UserAPI();

export class RoadbookAPI extends BackendAPI{

    constructor() { super('/roadbook'); }

    public generateTitle(roadbookType:string){
        return this.get(`/title/${roadbookType}`);
    }

    public displayOptions(roadbookId:string){
        return this.get(`/${roadbookId}/options/display`);
    }

    public updateDisplayOptions(roadbookId:string, data:any){
        return this.post(`/${roadbookId}/options/display`, data);
    }
}
export const roadbookAPI:RoadbookAPI = new RoadbookAPI();


export class DeviceAPI extends BackendAPI{

    constructor() { super('/device'); }

    public validate(serialNumber:string){
        return this.get(`/validate/${serialNumber}`);
    }
}
export const deviceAPI:DeviceAPI = new DeviceAPI();


export class GraphhopperAPI extends BackendAPI{

    constructor() { super('/graphhopper'); }

    public route(routeParams:any){
        return this.fetch(this.url()+`/route`, {
            method: 'POST',
            body: routeParams
        });
    
    }

    public routeDiscovery(routeParams:any){
        return this.fetch(this.url()+`/route/discovery`, {
            method: 'POST',
            body: routeParams
        });
    
    }

    public routeGet(routeParams:any){
        return this.fetch(this.url()+`/route`, {
            method: 'GET',
            params: routeParams,
        });
    }

    public profiles(){
        return this.get('/profiles');
    }
}
export const graphhopperAPI:GraphhopperAPI = new GraphhopperAPI();


export class LocationAPI extends BackendAPI{

    constructor() { super('/location'); }

    public search(query:string){
        return this.fetch(this.url()+`/search`, {
            method: 'GET',
            params: {q: query},
        });
    }
}
export const locationAPI:LocationAPI = new LocationAPI();


export class FavoriteAPI extends BackendAPI{

    constructor() { super('/location/favorite'); }
}
export const favoriteAPI:FavoriteAPI = new FavoriteAPI();